import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout/layout";

const About = () => {
    return (
        <Layout title="About">
            <h1>About</h1>
            <p>
                My name is Andrew. I am a full-stack developer based in the UK.
            </p>
            <p>
                Want to work with me? <Link to="/contact">Contact Me</Link>
            </p>
        </Layout>
    );
};

export default About;
